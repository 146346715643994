import React from 'react';
import PropTypes from 'prop-types';
import FormModal from '../fetch/FormModal';
import generalNoParameterFormPC from '../fetch/generalNoParameterFormPC';
import {GLOBAL_DATA} from '../../constants/globalData';
import generalFailedPC from '../fetch/generalFailedPC';
import Tooltip from '../general/Tooltip';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';
import { EventObj } from '../../constants/propTypesDefinitions';
import { createFetchEventSend } from '../../backend/apiCalls';
import withDataHOC from '../dataProvider/withDataHOC';

/**
 * @dusan
 */


 class EventSend extends React.PureComponent {
    static propTypes = {
        eventObj: EventObj.isRequired,
        reload: PropTypes.func.isRequired,
        type: PropTypes.string,
    };

    static defaultProps = {
        type: 'button'
    };

    render() {
        const {eventObj = {}, [GLOBAL_DATA.ACCOUNT_INFO]: account, reload, type} = this.props;
        const disabled = (eventObj.participants == null || eventObj.participants.find(p => p.id_user == account.id) == null);
        return <FormModal
            openNode={
                <Tooltip 
                    title={ disabled ?
                        <Trans>Nie ste účastník tohto eventu</Trans>
                        :
                        <Trans>Odoslať pozvánku</Trans>
                    }
                >
                    { type == 'link' ?
                        <div className="pl-2 link-button color-red"><Trans>Odoslať</Trans></div>
                        : <Button className="mx-1" size="small" type="primary" disabled={disabled} icon="export"/>
                    }
                </Tooltip>
            }
            values={{}}
            onFinishSuccessful={reload}
            title={<Trans>Odoslať pozvánku do kalendára</Trans>}
            Form={generalNoParameterFormPC(
                <Trans>Naozaj chcete odoslať pozvánku na event?</Trans>,
                createFetchEventSend(eventObj.id),
            )}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa odoslať pozvánku.`)}
        />;
    }

}

export default withDataHOC([GLOBAL_DATA.ACCOUNT_INFO])(EventSend);