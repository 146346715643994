import React from 'react';
import Select from '../general/Select';
import { MONTH_NAMES } from '../../constants/months';

/**
 * @dusan
 */

class MonthSelect extends React.PureComponent {
    render() {
        const {...props} = this.props;
        return <Select
            {...props}
            options={
                Object.entries(MONTH_NAMES).map(([value, label]) => {
                    return {label, value};
                })
            }
        />;
    }

}

export default MonthSelect;