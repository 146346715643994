import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';
import { downloadPdf } from '../../lib/url';

/**
 * @fero
 */

class PdfLink extends React.PureComponent {
    static propTypes = {
        objectType: PropTypes.string.isRequired,
        objectId: PropTypes.number.isRequired,
        disabled: PropTypes.bool,
        size: PropTypes.string,
    };

    render() {
        const {objectType, objectId, disabled, size} = this.props;
        return <a href={downloadPdf(objectType, objectId)} target="_blank">
            <Button disabled={disabled} size={size}>
                <Trans>PDF</Trans>
            </Button>
        </a>;
    }

}

export default PdfLink;
