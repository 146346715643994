import React from 'react';
import PropTypes from 'prop-types';

import Helmet from '../project/Helmet';
import TableWithFiltersLayout from '../project/TableWithFiltersLayout';
import {GLOBAL_DATA} from '../../constants/globalData';
import {EventObjs} from '../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';
import EventAdd from './EventAdd';
import EventParams from './EventParams';
import EventsTable from './EventsTable';
import withDataHOC from '../dataProvider/withDataHOC';
import ShowFuelReport from './ShowFuelReport';
import { QUERY_PARAMS } from '../../constants/navigation';

/**
 * @dusan
 */

class EventsPage extends React.PureComponent {
    static propTypes = {
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
        [GLOBAL_DATA.EVENTS]: EventObjs.isRequired,
    };

    reloadData = () => {
        const reload = this.props[GLOBAL_DATA.RELOAD_DATA];
        reload([GLOBAL_DATA.EVENTS]);
    };

    render() {
        const {[GLOBAL_DATA.EVENTS]: events} = this.props;
        return <React.Fragment>
            <Helmet
                title={t`Eventy`}
            />
            <TableWithFiltersLayout
                title={<Trans>Eventy</Trans>}
                params={
                    <div>
                        <EventParams/>
                        <EventAdd 
                            wrapperClassName="px-3 pt-2" 
                            reload={this.reloadData}
                        />
                        <ShowFuelReport
                            wrapperClassName="pt-2" 
                        />
                    </div>
                }
                table={
                    <EventsTable
                        reload={this.reloadData}
                        events={events}
                    />
                }
                scrollParam={QUERY_PARAMS.EVENT_SCROLL}
            />
        </React.Fragment>;
    }

}

export default withDataHOC([GLOBAL_DATA.EVENTS, GLOBAL_DATA.RELOAD_DATA])(EventsPage);