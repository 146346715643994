import React from 'react';
import locationHOC from '../locationProvider/locationHOC';
import UserSelectByCustomer from '../project/UserSelectByCustomer';
import CustomersSelect from '../project/CustomersSelect';
import ColumnFilterLayout from '../project/ColumnFilterLayout';
import DateRangePicker from '../general/DateRangePicker';
import InputText from '../general/InputText';
import {getQueryParam, getQueryParamNumber,  createSetQueryParamValue, navigateToParametrized} from '../../lib/url';
import {QUERY_PARAMS} from '../../constants/navigation';
import {Location} from '../../constants/propTypesDefinitions';
import {Button, Radio} from 'antd';
import {Trans, t} from '@lingui/macro';
import EventTypesSelect from '../project/EventTypesSelect';
import VehiclesSelect from '../project/VehiclesSelect';
import EmployeesSelect from '../project/EmployeesSelect';
import { getInputOnChangeEventValue } from '../../lib/project';

/**
 * @dusan
 */

class EventParams extends React.PureComponent {
    static propTypes = {
        location: Location.isRequired,
    };

    clearAllParams = () => {
        const {location} = this.props;
        navigateToParametrized(location, null, {
            [QUERY_PARAMS.EVENT_ID_TYPE]: undefined,
            [QUERY_PARAMS.EVENT_ID_WORKER]: undefined,
            [QUERY_PARAMS.EVENT_ID_USER]: undefined,
            [QUERY_PARAMS.EVENT_ID_CUSTOMER]: undefined,
            [QUERY_PARAMS.EVENT_ID_VEHICLE]: undefined,
            [QUERY_PARAMS.EVENT_START_AT]: undefined,
            [QUERY_PARAMS.EVENT_HAS_REFUEL]: undefined,
            [QUERY_PARAMS.EVENT_PHRASE]: undefined,
            [QUERY_PARAMS.EVENT_ORDER_BY]: undefined,
            [QUERY_PARAMS.EVENT_SCROLL]: undefined,
            [QUERY_PARAMS.EVENT_LIMIT]: process.env.DEFAULT_TABLE_MIN_RESULT_LIMIT,
        });
    };

    render() {
        const {location} = this.props;
        const typeId = getQueryParamNumber(location, QUERY_PARAMS.EVENT_ID_TYPE);
        const workerId = getQueryParamNumber(location, QUERY_PARAMS.EVENT_ID_WORKER);
        const userId = getQueryParamNumber(location, QUERY_PARAMS.EVENT_ID_USER);
        const customerId = getQueryParamNumber(location, QUERY_PARAMS.EVENT_ID_CUSTOMER);
        const vehicleId = getQueryParamNumber(location, QUERY_PARAMS.EVENT_ID_VEHICLE);
        const startAt = getQueryParam(location, QUERY_PARAMS.EVENT_START_AT);
        const hasRefuel = getQueryParamNumber(location, QUERY_PARAMS.EVENT_HAS_REFUEL);
        const phrase = getQueryParam(location, QUERY_PARAMS.EVENT_PHRASE);
       
        const hasFilter = typeId != null || workerId != null || userId != null || customerId != null || vehicleId != null || 
            startAt != null || hasRefuel != null || phrase != null;
        return <ColumnFilterLayout
            className="p-1"
            filters={[
                [
                    {
                        label: <Trans>Vyhľadávať: </Trans>,
                        selector: <InputText
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.EVENT_PHRASE)}
                            value={phrase}
                        />
                    },
                    {
                        label: <Trans>Typ eventu: </Trans>,
                        selector: <EventTypesSelect
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.EVENT_ID_TYPE)}
                            value={typeId}
                            allowClear={true}
                        />
                    },
                    {
                        label: <Trans>Vozidlo: </Trans>,
                        selector: <VehiclesSelect
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.EVENT_ID_VEHICLE)}
                            value={vehicleId}
                            allowClear={true}
                        />
                    }
                ],
                [
                    {
                        label: <Trans>Predajca / skladník: </Trans>,
                        selector: <EmployeesSelect
                            className="full-size-width"
                            value={workerId}
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.EVENT_ID_WORKER)}
                            allowClear={true}
                        />
                    },
                    {
                        label: <Trans>Organizácia: </Trans>,
                        selector: <CustomersSelect
                            className="full-size-width"
                            value={customerId}
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.EVENT_ID_CUSTOMER)}
                            allowClear={true}
                        />
                    },
                    {
                        label: <Trans>Kontakt: </Trans>,
                        selector: <UserSelectByCustomer
                            className="full-size-width"
                            value={userId}
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.EVENT_ID_USER)}
                            allowClear={true}
                            customerId={customerId}
                        />
                    },                    
                ],
                [
                    {
                        label: <Trans>Dátum: </Trans>,
                        selector: <DateRangePicker
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.EVENT_START_AT)}
                            value={startAt}
                        />,
                    },
                    {
                        label: <Trans>Tankovanie: </Trans>,
                        selector: <Radio.Group
                            onChange={(e) => {
                                const value = getInputOnChangeEventValue(e);
                                createSetQueryParamValue(location, QUERY_PARAMS.EVENT_HAS_REFUEL)(value);
                            }}
                            value={hasRefuel}
                        >
                            <Radio.Button value={0}><Trans>Nie</Trans></Radio.Button>
                            <Radio.Button value={1}><Trans>Áno</Trans></Radio.Button>
                            <Radio.Button value={undefined}><Trans>Všetky</Trans></Radio.Button>
                        </Radio.Group>
                    },
                    {
                        label: null,
                        selector: <Button
                            type={hasFilter ? "danger" : null}
                            onClick={this.clearAllParams}
                            className="full-size-width"
                        >
                            <Trans>Vyčistiť všetky filtre</Trans>
                        </Button>,
                    }
                ]
            ]}
        />;
    }

}

export default locationHOC(EventParams);