import React from 'react';
import PropTypes from 'prop-types';
import FormModal from '../fetch/FormModal';
import generalFailedPC from '../fetch/generalFailedPC';
import {Button} from 'antd';
import {ButtonSize} from '../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';
import FuelReportForm from './FuelReportForm';

/**
 * @dusan
 */


class ShowFuelReport extends React.PureComponent {
    static propTypes = {
        wrapperClassName: PropTypes.string,
        type: PropTypes.string,
        size: ButtonSize,
        reload: PropTypes.func.isRequired,
    };

    static defaultProps = {
        wrapperClassName: '',
        type: '',
    };

    render() {
        const {wrapperClassName, type, size, reload} = this.props;
        return <FormModal
            className={wrapperClassName}
            openNode={
                <Button type={type} size={size}>
                    <Trans>Výkaz PHM</Trans>
                </Button>
            }
            values={{}}
            onFinishSuccessful={reload}
            title={<Trans>Výkaz PHM</Trans>}
            Form={FuelReportForm}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa vygenerovať report.`)}
        />;
    }

}

export default ShowFuelReport;