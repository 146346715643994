import React from 'react';
import PropTypes from 'prop-types';
import {Trans, t} from '@lingui/macro';
import Link from '../navigation/Link';
import { ROUTES, QUERY_PARAMS } from '../../constants/navigation';

/**
 * @fero
 */

class CustomerLink extends React.PureComponent {
    static propTypes = {
        customerId: PropTypes.number.isRequired,
        customerName: PropTypes.string.isRequired,
    };

    render() {
        const {customerId, customerName} = this.props;
        return <Link
            className="text-dark"
            to={ROUTES.CUSTOMER_DETAILS}
            queryParams={{[QUERY_PARAMS.ID_PAGE_CUSTOMER]: customerId}}
            title={<Trans>Zobraziť detail organizácie</Trans>}
        >
            {customerName}
        </Link>;
    }

}

export default CustomerLink;